import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import stripePromise from './stripe';
import StripeCheckout from 'react-stripe-checkout';
import SubscriptionCheckout from './SubscriptionCheckout';
import axios from 'axios';

const ProductDisplay = () => (
    <section>
      <div className="product">
    </div>
      <form onSubmit={handleSubmit}>
          {/* Add input fields for your post data */}
          {/* For example: */}
          {/* <input type="text" name="title" value={postData.title} onChange={handleChange} placeholder="Title" />
          <textarea name="body" value={postData.body} onChange={handleChange} placeholder="Body" /> */}
         
        {/* Add a hidden field with the lookup_key of your Price http://localhost:4242/create-checkout-session */}
        <input type="hidden" name="lookup_key" value="price_1P3Y3G00vJtpONmaaZc03MxG" />
        <button className="dreambtn mb-3 btn " id="checkout-and-portal-button" type="submit" onClick={handleSubmit} style={{ backdropFilter: 'blur(5.5px)' }}>Continue</button> 

      </form>
    </section>
  );


const SuccessDisplay = ({ sessionId }) => {
    return (
      <section>
        <div className="product Box-root">
          <Logo />
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form action="/create-portal-session" method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </section>
    );
  };
  
  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Define the URL of your Node.js API endpoint
      const apiUrl = 'https://kxhwponk5l.execute-api.us-east-1.amazonaws.com/default/api-pf/create-checkout-session'; // Replace this with your actual API endpoint
      const postData ="price_1P3Y3G00vJtpONmaaZc03MxG";
      // Make a POST request to the API using Axios
      const response = await axios.post(apiUrl, postData);
  
      // Set the response data in the state
     // setResponse(response.data.url);
     window.location.href= response.data.session.url;
  //console.log(response.data.session.url);
     //window.location.href = response.data.url; // This will cause the browser to navigate to the specified URL.
  
     
     
    } catch (error) {
      // Handle errors
     // setError(error.message);
    };
  }; 
  const Logo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
    >
      <defs />
      <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="0-Default"
          transform="translate(-121.000000, -40.000000)"
          fill="#E184DF"
        >
          <path
            d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
            id="Pilcrow"
          />
        </g>
      </g>
    </svg>
  );

export default function Offer(props) {

    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    
    useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
  
      if (query.get('success')) {
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }
  
      if (query.get('canceled')) {
        setSuccess(false);
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
      }
    }, [sessionId]);


    const priceId = 'price_1P3Y3G00vJtpONmaaZc03MxG'; // Pass the price ID here
    const productPrice = 5.99; // Set your product price here
    const successUrl = '/success'; // Set your success URL here
    const failureUrl = '/failure'; // Set your failure URL here
    const [isClicked, setIsClicked] = useState("row fadecss");
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/payment');
        }, 500);
        }
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'offer');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
  const handleToken = (token) => {
    navigate('/success');
    
  };
    return (
    <div className={isClicked}>
        <div className="col-sm-12 col-lg-12 text-center">
            <h1 style={{paddingTop:"120px"}}></h1>
            <div className="limited_offer">Limited Time Offer <br/><span className="dollarp">$5.99/monthly</span></div>
            <br/>
            <p className="yourval_offer btn ">
                <span className="try_calm">Unlock the full experience now!</span><br/>
                <span className="yourval_text2">Included in your basic membership</span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-secondary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Limited access to content<br/>Your premium membership includes
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Full-length Souls Originals for you daily
                </span><br/>
                
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Unlimited access to content
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Unlimited chat with Soul AI
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Variety of themes and voices for your Soul <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Originals
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Effective sleep, mediation, relaxing content
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;New Updates every week
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Cancel anytime
                </span>
            </p>
             <br/>
            <div className="checkout" >
             <ProductDisplay />
              {/*<Elements stripe={stripePromise}>
                <CheckoutForm price={productPrice} successUrl={successUrl} failureUrl={failureUrl} />
              </Elements>*/}

            {/*<SubscriptionCheckout />
            <StripeCheckout
              stripeKey="pk_live_51P3KoE00vJtpONmaxHb7rMl33kghtXgYItUK1jPIKFAcsvdp2xhHzNs4xyAwZFaC7zgPoTHfzizVskVebZe3Hl9j00euPco0fc"
              token={handleToken}
              amount={599} // Amount in cents
              currency="USD"
              name="Soul"
              description="Product Payment"
              // Omitting billingAddress and shippingAddress props
              // billingAddress
              // shippingAddress
            >
              <button className="skipbtn btn mb-3">Pay Now</button>
            </StripeCheckout>*/}
            </div>
            <NavLink to="https://apps.apple.com/app/soul-personal-sleep-stories/id6479317391" >Skip for now</NavLink>

            <br/>
            <br/>
        </div>
    </div>
    );
}