import React, { useState,useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner';

export default function Signup(props) {
    const navigate = useNavigate();
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
    const [formData, setFormData] = useState({
    name: '',
    email: ''
    });
    const [isClicked, setIsClicked] = useState("row fadecss");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'signup');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    };
    const validateForm = () => {
        let formErrors = {};
        if (!formData.name.trim()) {
          formErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
          formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          formErrors.email = 'Email is invalid';
        }
        
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
          e.preventDefault();

          if (validateForm()) {
           try {
            setLoading(true);
            const updateresponse = await fetch(URL + 'update-profile', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                 userID:localStorage.getItem("id"),
                 email : formData.email,
                 name:formData.name,
                 deviceId:"",
                 deviceToken:"",
                 fcmToken:""
             })
              //body: JSON.stringify(formData)
            });
            const updatejson = await updateresponse.json();
            if(updatejson.id === localStorage.getItem("id")){
              localStorage.setItem('name', formData.name);
              const response = await fetch(URL + 'send-otp', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                   userID:localStorage.getItem("id"),
                   email : formData.email,                  
               })
                //body: JSON.stringify(formData)
              });
              const json = await response.json();
              setIsClicked("row element");
              setTimeout(() => {
                  setLoading(false);
                 navigate('/verify-otp');
              }, 500);
            }
            
          } catch (error) {
            console.error('Error registering user:', error);
          }
        }
    };
    
    const handleGoogleLogin = () => {
        // Open Google's OAuth 2.0 authentication window
        window.location.href = 'https://accounts.google.com/o/oauth2/auth?' +
          'redirect_uri=http://localhost:3000/callback' +
          '&response_type=token' +
          '&client_id=676513104310-j21ud9pcbm1o2hmgb6dr2lg7b8tf3lvd.apps.googleusercontent.com' +
          '&scope=email%20profile' +
          '&approval_prompt=auto';
    };
    
    return (
    <div className={isClicked}>
    {loading ? (
        <LoadingSpinner />
      ) :(
        <div className="col-sm-12 col-lg-12 text-center">
            <div className="h1test"><h1 className="create_h">Once you finish signup below,<br/> you’ll get access to your subscription<br/> and great daily content!
            <br/></h1></div> 
            <div>
            <form onSubmit={handleSubmit}>
            <input type="text" value={formData.name}  name="name" placeholder="First name" className="dreambtn mb-3 btn" onChange={handleChange}/>
            {errors.name && <div className="error">{errors.name}</div>}
            <input type="email" value={formData.email} name="email" placeholder="Email address" className="dreambtn mb-3 btn" onChange={handleChange}/>
            {errors.email && <div className="error">{errors.email}</div>}
             <button className={(formData.name.length === 0 && formData.email.length === 0)?'btn mb-3 contin_c':'btn mb-3 skipbtn'} type="submit">Continue with Email</button> 
            </form>
            <div className="line"></div>

            </div> 
            <br/>
            <button className="btn mb-3 contin_c_google" onClick={handleGoogleLogin}>Continue with Google</button> <br/><br/><br/><br/>
        </div>)
        };
    </div>
    );
}