import React, { useState, useEffect } from 'react';

const LoadingSpinner = () => {
  const messages = ["Your Soul Original is ready...", "Soul Originals are stories about you!", "Check this one out for free..."];
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let messageIndex = 0;

    const changeMessage = () => {
      setMessage(messages[messageIndex % messages.length]);
      setVisible(true); // Show the message

      setTimeout(() => {
        setVisible(false); // Hide the message for the fade-out effect
        messageIndex++; // Move to the next message

        // Schedule the next message change after the current message fades out
        setTimeout(changeMessage, 500); // This waits for the fade-out to complete
      }, 3000); // Each message is visible for 3 seconds
    };

    changeMessage();

    return () => {
      // Placeholder for cleanup logic if necessary
    };
  }, []);

  return (
    <>
      <style>
        {`
          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            text-align: center;
            padding:35px;
            width: 100vw; /* Ensure the container spans the full width of the viewport */
          }

          .fade-in-out {
            font-size: 24px; /* Slightly smaller text as requested */
            opacity: 0;
            transition: opacity 2.5s ease-in-out;
            width: 100%; /* Ensure the text spans the full width of its container */
          }

          .fade-in-out.visible {
            opacity: 1;
          }
        `}
      </style>
      <section className="spinner-container">
        <h1 className={`fade-in-out ${visible ? 'visible' : ''}`}>
          {message}
        </h1>
      </section>
    </>
  );
};

export default LoadingSpinner;
