import './App.css';
import {useNavigate,Navigate,useLocation, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

function App() {
  const location = useLocation();

  // Check if the current URL is '/success'
  const isSuccessRoute = location.pathname === '/success';
 {!isSuccessRoute && <Route path="/success" element={<Navigate to="/success" replace />} />}

  return (
    <>
    </>
  );
}

export default App;
