import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'

const SuccessPage = () => {
  const [isClicked, setIsClicked] = useState("row fadecss");
  const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
  const navigate = useNavigate();
  const handleSubmit = (e) => {
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/signup');
        }, 500);
    }
  useEffect(() => {
    // Trigger the Purchase event when component mounts
    // eslint-disable-next-line
    fbq('track', 'Purchase');
  }, []);

  useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'success');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
  return (
  <div className={isClicked}>
        <div className="col-sm-12 col-lg-12 text-center">
            <h1></h1>
            <div className="limited_offer_succss">Congratulations!<br/>
            <span className="limited_next">You're a premium member now</span>
            </div>
            <br/>
             <br/>
            <p className="yourval_offer btn ">
                {/*<span className="try_calm">Try Calm Premium for 14 days</span><br/>
                <span className="yourval_text2">Included in your basic membership</span><br/>*/}
                <span className="yourval_text2">
                    &nbsp;&nbsp;Your premium membership includes
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Daily Souls Originals for you
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Unlimited access to content
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Unlimited chat with Soul AI
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Variety of themes and voices for your Soul <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Originals
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Effective sleep, mediation, relaxing content
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;New Updates every week
                </span><br/>
                <span className="yourval_text2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="rightcheck_i bi bi-check btn-primary rounded-circle" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"></path>
                    </svg> 
                    &nbsp;&nbsp;Cancel anytime
                </span>
            </p>
             <br/>
            <button className="skipbtn btn mb-3" value="continue" onClick={handleSubmit}>Complete Your Signup</button> <br/>
            <br/>
            <br/>
        </div>
    </div>

  );
};

export default SuccessPage;
