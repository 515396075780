import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ price, successUrl, failureUrl }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    setLoading(false);

    if (error) {
      console.error(error);
      navigate(failureUrl);
    } else {
      console.log('Payment successful:', paymentMethod);
      // Send payment data to server
      navigate(successUrl);
      //sendPaymentDataToServer(paymentMethod);
    }
  };

  const sendPaymentDataToServer = async (paymentMethod) => {
    try {
      const response = await fetch('https://example.com/payments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentMethod }),
      });

      if (response.ok) {
        console.log('Payment data sent to server successfully');
        // Optionally, handle server response
      } else {
        console.error('Failed to send payment data to server:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending payment data to server:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
   {/* <h2 style={{ marginBottom: '1rem' }}>Checkout</h2>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ fontWeight: 'bold' }}>Product Price:</label>
        <span style={{ marginLeft: '0.5rem' }}>${price}</span>
      </div>*/}
      <div style={styles.formGroup}>
        <label style={styles.label}>Card Information</label>
        <CardElement options={cardStyle} />
      </div>
      <button type="submit" style={styles.button} disabled={!stripe || loading}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </form>
  );
};

const cardStyle = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const styles = {
  form: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    backgroundColor: '#f5f5f5',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#333',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export default CheckoutForm;
