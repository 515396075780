// FailurePage.js
import React from 'react';

const FailurePage = () => {
  return (
     <div className="row fadecss">
        <div className="col-sm-12 col-lg-12 text-center">
            <h1 className="stayh1"> Failed Page</h1>
            <br/>
            <br/>
            
        </div>
    </div>
  );
};

export default FailurePage;
