import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'

export default function Details(props) {
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
    const [idols, setIdols] = useState([])
    useEffect(() => async (e) => {
          const response = await fetch(URL + 'getIdols', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
          const json = await response.json();
          setIdols(json)
    })

    return (
    <div className="row fadecss">
        <div className="col-sm-12 col-lg-12 text-center">
            <h1>What would you like to do?</h1>
            <p>Select all that apply</p>
            <div className="row">
                {idols.map((note) => {
                    return (<div className="col-sm-12 col-lg-4" key={note.id} >
                            <img src="img/profile.png" height="100px" width="100px" className="profilec"/>
                            <p> <br/><NavLink to="/value">{note.IdolName}  {note.id} </NavLink></p>
                        </div>);
                })}
             </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
    );
}