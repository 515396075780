import React, { useEffect,useState  } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
export default function Callbackgoogle(props) {
    
const [userData, setUserData] = useState(null);
useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'callback');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      // Parse the access token from the URL
      const params = new URLSearchParams(window.location.hash.substr(1));
      const accessToken = params.get('access_token');

      if (accessToken) {
        try {
          // Fetch user information from Google API
          const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            console.error('Failed to fetch user information from Google API');
          }
        } catch (error) {
          console.error('Error fetching user information:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return <div>Logging in...</div>;
  }

    
    return (
    <div className="row fadecss">
        <div className="col-sm-12 col-lg-12 text-center">
            <h2>Welcome, {userData.name}!</h2>
             <p>Email: {userData.email}</p>
            <div>
            </div> 
            <br/>
            <NavLink to="https://apps.apple.com/app/soul-personal-sleep-stories/id6479317391" >Explore The App <br/> <br/><img src="img/logoapp.png" width="150" height="37px"/></NavLink>
        </div>
    </div>
    );
}