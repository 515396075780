import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'

export default function Welcome(props) {
    const [isClicked, setIsClicked] = useState("row fadecss");
	const navigate = useNavigate();
    const handleSubmit = async (e) => {
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/user');
        }, 500);
    }
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'welcome');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
	return (
    <div className={isClicked}>
        <div className="col-sm-12 col-lg-12 text-center">
            <h1 className="welh1">Welcome to Soul!</h1>
            <p className="cong"><span className="congsp">Congratulations</span> on taking the first step to<br/> wards a mentally stronger you.</p>
            <p className="cong2">Let's get started! Just a few questions to<br/> <span className="congsp">personalize</span> your experience.</p> <br/>
            <p className="dreambtn mb-3 btn didspan" style={{ backdropFilter: 'blur(15.5px)' }}>Did you know?<br/>
               <span className="calmspan"> 84% of Soul users who used the app 5x<br/> 
               a week saw an improvement in their mental <br/> 
                health. </span>
            </p> <br/>
            <button className="skipbtn btn mb-3" value="continue" onClick={handleSubmit}>Continue</button> <br/>
            <NavLink to="/user">Skip for now</NavLink>
        </div>
    </div>
    );
}