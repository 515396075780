import React, { useState,useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner';

export default function User(props) {
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [isClicked, setIsClicked] = useState("row fadecss");
    const navigate = useNavigate();
    useEffect(() => {
        getConfig()
        
    }, [])
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'user');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    const getConfig = async (e) => {
        const response = await fetch(URL + 'getConfigVeriables', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           })
        });
        const json = await response.json();
        localStorage.setItem('PERSONALIZE_PROMT', json.allEnvVariables.PERSONALIZE_PROMT);
        localStorage.setItem('audioLink', json.allEnvVariables.Onboarding_audio);
        
    }
    const getSpeech = async (name) => {
        const response = await fetch(URL + 'convert-text-to-speech', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( { "userID": "", "idolId":"654321", "text": "Hello "+name })
        });
        const json = await response.json();
        //console.log(json.audioUrl);
        localStorage.setItem('speechurl', json.audioUrl);
        
    }
    const handleSubmit = async (e) => {
        if(name === ""){
            return false;
        }
        setLoading(true);
        //getSpeech(name)
        //navigate('/details');
        e.preventDefault();
        const response = await fetch(URL + 'CreateUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                 name: name,
                 deviceId: "",
                 deviceToken: "",
                 fcmToken: "",
                 purpose: localStorage.getItem("purpose"),
             })
        });
        const json = await response.json();
        localStorage.setItem('name', name);
        localStorage.setItem('id', json.id);
        console.log(json);
        setIsClicked("row element");
        setTimeout(() => {
            setLoading(false);
           navigate('/value');
        }, 500);
        // if (json.success){
        //     // Save the auth token and redirect
        //     localStorage.setItem('token', json.authtoken); 
        //     history.push("/");

        // }
        // else{
        //     alert("Invalid credentials");
        // }
    }

    const onChange = (e)=>{
        setName(e.target.value);
    }   
    
    return (
    <div className={isClicked}>
    {loading ? (
        <LoadingSpinner />
      ) :(
        <div className="col-sm-12 col-lg-12 text-center">
             <br/><br/>
            <h1 className="whatsn"> What's Your Name</h1>
            <br/><br/><br/>
            <div><input type="text" value={name} placeholder="Your Name" className="dreambtn" onChange={onChange}/></div> <br/>
            <button className={(name.length === 0)?'btn mb-3 contin_c':'btn mb-3 skipbtn'} onClick={handleSubmit}>Continue</button> <br/><br/><br/><br/>
        </div> )
        };
    </div>
    );
}