import React, { useRef, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';

const Chat = () => {
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/";
      const chatContainerRef = useRef(null);

    const openAiURL = "https://api.openai.com/v1/chat/completions";
    const [messagesSent, setMessagesSent] = useState(0);
    const totalMessages = 3;  // Adjust this to set how many messages count towards "completion"
    const [conversation, setConversation] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [isClicked, setIsClicked] = useState("row fadecss");
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'chat');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    useEffect(() => {
        // Calculate progress whenever messagesSent updates
        setProgress((messagesSent / totalMessages) * 100);
    }, [messagesSent]);

    useEffect(() => {
        res("", localStorage.getItem("PERSONALIZE_PROMT"))
            .then((res) => {
                if (res.status === 200) {
                    setConversation([{ sender: 'bot', text: res.data?.choices[0]?.message?.content }]);
                    setMessagesSent(prev => prev + 1);
                }
            });
    }, []);

    const res = async function botMessaging(message = "", promt) {
        let openAIPromt = promt ? promt : "";

        let payload = {
            "messages": [{ "role": "system", "content": openAIPromt }, { "role": "user", "content": message }],
            "model": 'gpt-3.5-turbo',
            "max_tokens": 200
        };

        return axios.post(openAiURL, payload, {
            headers: { Authorization: "Bearer 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" }
        })
        .then(res => res)
        .catch(e => {
            console.error(e);
            return e;
        });
    };

    const sendMessage = async () => {
        if (!userInput) return;
        if (messagesSent >= totalMessages) {
            navigate('/video');
            return;
        }
        setConversation(prevConversation => [...prevConversation, { text: userInput, sender: "user" }]);
        setMessagesSent(prev => prev + 1);

        const response = await axios.post(openAiURL, {
            "model": 'gpt-3.5-turbo',
            messages: [{ role: 'system', content: 'User: ' + userInput }, { role: 'system', content: 'Bot: ' }],
            max_tokens: 200
        }, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer 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' }
        });

        const botResponse = response.data?.choices?.[0]?.message?.content;
        if (botResponse) {
            setConversation(prevConversation => [...prevConversation, { text: botResponse, sender: "bot" }]);
        } else {
            console.error("Error: Unable to get AI response");
        }
        setUserInput('');
    };

    const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);

    const handleSubmit = (e) => {
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/video');
        }, 500);
    }

    return (
        <div className={isClicked}>
            <div className="col-sm-12 col-lg-12 text-center">
                <h1></h1>
                <br/>
                <p className="laststep_p">Last step to <br/> personalize your Soul</p><br/>
                <div className="messages-container"  ref={chatContainerRef}>
                
                    {conversation.map((message, index) => (
                        <div key={index} className={message.sender === 'bot' ? 'chatleft' : 'chatright'}>
                            <p className={message.sender === 'bot' ? 'laststep_hello mb-3 btn_chat' : 'lastste_reply mb-3 btn_chat'}>
                                {message.text}
                            </p>
                        </div>
                    ))}
                    </div>
                   {/* <div ref={messagesEndRef} >
                    </div>*/}
                 </div>  
                 <div className="col-sm-12 col-lg-12 text-center"> 
                <div className="input-area">
                <LinearProgress variant="determinate" value={progress} />
                <br></br>

                    <input
                        className="chatbtn"
                        placeholder="Type your message..."
                        type="text"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                    />
                    <button type="button" className="send-button btn btn-primary btn-block send_btn" onClick={sendMessage}>
                        Send
                    </button>
                    <NavLink to="#" className="skip-link" onClick={handleSubmit}>Skip for now</NavLink>
                </div>
                <br/><br/>
            </div>
        </div>
    );
};

export default Chat;
