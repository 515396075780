import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'

export default function Home(props) {
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState("row fadecss");
    const handleSubmit = async (e) => {
        localStorage.setItem('purpose', e.target.value); 
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/welcome');
        }, 500);
        }
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'home');
        return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
	return (
    <div className={isClicked}>
        <div className="col-sm-12 col-lg-12 text-center">
            <br/>
            <br/>
            <h1 className="what_would">What would you like to do?</h1>
            <p>Select all that apply</p>
            <button className="dreambtn mb-3 btn " value="Sleep Better" onClick={handleSubmit} style={{ backdropFilter: 'blur(5.5px)' }}>Sleep Better</button> <br/>
            <button className="dreambtn mb-3 btn " value="Dream Better" onClick={handleSubmit} style={{ backdropFilter: 'blur(5.5px)' }}>Focus Better</button> <br/>
            
            <button className="dreambtn mb-3 btn " value="Get Motivated" onClick={handleSubmit} style={{ backdropFilter: 'blur(5.5px)' }}>Get Motivated</button> <br/>
            {/*<button className="skipbtn btn mb-3" value="continue" onClick={handleSubmit}>Continue</button> <br/>
            <NavLink to="/welcome" value="skip_for_now" >Skip for now</NavLink>*/}
        </div>
    </div>
    );
}