import React from 'react'
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <section>
        <div className="">
            <div className="row">
                <div className="col-sm-12 col-lg-4"></div>
                <div className="col-sm-12 col-lg-4">
                    <div className="maindata">
                     {/* <div className="video-wrapper">
                          <video playsInline autoPlay muted loop poster="img/bg.png">
                            <source src="img/video.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        </div>*/}
                       <Outlet /> 

                    </div>
                </div>
            </div>
        </div>
    </section>  
  )
}

export default Layout