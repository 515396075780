import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SubscriptionForm from './SubscriptionForm'; // Create this component next

const stripePromise = loadStripe('pk_live_51P3KoE00vJtpONmaxHb7rMl33kghtXgYItUK1jPIKFAcsvdp2xhHzNs4xyAwZFaC7zgPoTHfzizVskVebZe3Hl9j00euPco0fc');

const SubscriptionCheckout = ({ priceId }) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm priceId={priceId} />
    </Elements>
  );
};

export default SubscriptionCheckout;
