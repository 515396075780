import React, { useRef, useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
import AutoPlayAudio from './AutoPlayAudio';

export default function Value(props) {
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
    const [isClicked, setIsClicked] = useState("row fadecss");
    const [audioLink, setAudioLink] = useState(null);
      const navigate = useNavigate();

      const audio1Ref = useRef(null);
      const audio2Ref = useRef(null);

      useEffect(() => {
        setTimeout(() => {
          navigate('/chat');
            //navigate('/offer');
        }, 2000);
        // Start playing the first audio when the component mounts
        //audio1Ref.current.play();
      }, []);
      useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'value');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
      const handleAudio1End = () => {
        // When the first audio ends, start playing the second audio
        audio2Ref.current.play();
      };

      const handleAudio2End = () => {
        // Optionally, you can do something when the second audio ends
        console.log('Second audio ended');
      };

    const handleSubmit = (e) => {
        setIsClicked("row element");
        setTimeout(() => {
          navigate('/chat');
            //navigate('/offer');
        }, 500);
    }
	return (
    <div className={isClicked}>
        <div className="col-sm-12 col-lg-12 text-center">
            <h1></h1>
            <p></p>
            <p className="yourval mb-3 btn ">
            <span className="yourval_text">Hi {localStorage.getItem("name")}! <br/> <span className="turnup">Please turn up the volume</span></span><br/>
            {/*<span className="yourval_text2">by Ben Lionel Scott <br/>Confidence</span>*/}
            </p> <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div>
              {/*<h1>Audio Player</h1>*/}
              {/*<AutoPlayAudio audioSrc={localStorage.getItem("audioLink")} />*/}
               <audio ref={audio1Ref} onEnded={handleAudio1End} style={{ display: 'none' }}>
                <source src={localStorage.getItem("speechurl")} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <audio ref={audio2Ref} onEnded={handleAudio2End} style={{ display: 'none' }}>
                <source src={localStorage.getItem("audioLink")} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
               
            <NavLink to="#" onClick={handleSubmit}>Skip for now</NavLink>
            <br/>
            <br/>
        </div>
    </div>
    );
}