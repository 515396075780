import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {useNavigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Layout from './Layout.jsx'
import Home from './components/Home';
import Welcome from './components/Welcome';
import User from './components/User';
import Details from './components/Details';
import Value from './components/Value';
import Chat from './components/Chat';
import Notifications from './components/Notifications';
import SuccessPage from './components/SuccessPage';
import FailurePage from './components/FailurePage';
import PaymentPage from './components/PaymentPage';
import Signup from './components/Signup';
import Callbackgoogle from './components/Callbackgoogle';
import Verifyotp from './components/Verifyotp';
import Video from './components/Video';
import Offer from './components/Offer';
import Callback from './components/Callback';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='' element={<Home />} />
      <Route path='welcome' element={<Welcome />} />
      <Route path="user" element={< User />}/>
      <Route path='details' element={<Details />} />
      <Route path='value' element={<Value />} />
      <Route path='chat' element={<Chat />} />
      <Route path='video' element={<Video />} />
      <Route path='offer' element={<Offer />} />
      <Route path='payment' element={<PaymentPage />} />
      <Route path='success' element={<SuccessPage />} />
      <Route path='failure' element={<FailurePage />} />
      <Route path='signup' element={<Signup />} />
      <Route path='callback' element={<Callbackgoogle />} />
      <Route path='verify-otp' element={<Verifyotp />} />
      <Route path='callback-web' element={<Callback />} />
     </Route>
  )
)



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
           <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
