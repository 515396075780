import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {NavLink,useNavigate} from 'react-router-dom'

const SubscriptionForm = ({ priceId }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
    } else {
      // Send payment method and subscription details to backend
      // for subscription creation, including the priceId
      console.log(paymentMethod, priceId);
      navigate('/success');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="card-details-form">
         <label className="card-details-label">
          Card Details
          <CardElement className="card-details-element" />
          </label>
      <button type="submit" disabled={!stripe} className="submit-button">
        Pay
      </button>
    </form>
  );
};

export default SubscriptionForm;
