import React, { useState,useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner';

export default function Verifyotp(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState("row fadecss");
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/"
    const [formData, setFormData] = useState({
    otp: '',
    email:''
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'verify-otp');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    };
    const validateForm = () => {
        let formErrors = {};
        if (!formData.otp.trim()) {
          formErrors.name = 'otp is required';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
          e.preventDefault();
          if (validateForm()) {
           try {
            setLoading(true);
            const response = await fetch(URL + 'verify-otp', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                 email : formData.email,
                 otp: formData.otp
             })
              //body: JSON.stringify(formData)
            });
            localStorage.setItem('email', formData.email);
            const json = await response.json();
            console.log(json);
            if(json.error === 'Invalid OTP'){
              setLoading(false);
              alert('Otp is invalid')
            }
            else{
                setTimeout(() => {
                setLoading(false);
                navigate('/callback-web');
               }, 500);
            }
           
            
          } catch (error) {
            console.error('Error OTP:', error);
          }
        }
    };
    
    return (
    <div className={isClicked}>
    {loading ? (
        <LoadingSpinner />
      ) :(
        <div className="col-sm-12 col-lg-12 text-center">
            <h1>Enter Code</h1>
            <div>
            <form onSubmit={handleSubmit}>
            <input type="email" value={formData.email} name="email" placeholder="Email address" className="dreambtn mb-3 btn" onChange={handleChange}/>
            {errors.email && <div className="error">{errors.email}</div>}
            <input type="number" value={formData.otp}  name="otp" placeholder="Enter Code" className="dreambtn mb-3 btn" onChange={handleChange}/>
            {errors.otp && <div className="error">{errors.otp}</div>}
              <button className={(formData.email.length === 0 && formData.otp.length === 0)?'btn mb-3 contin_c':'btn mb-3 skipbtn'} type="submit">Submit</button> 
            </form>
            </div> 
            <br/>
        </div>)
        };
    </div>
    );
}