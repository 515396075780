import React, { useState,useEffect } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
//import AutoPlayAudio from './AutoPlayAudio';
import LoadingSpinner from './LoadingSpinner';

export default function Video(props) {
    const [playAudio, setPlayAudio] = useState(false);
    const URL = "https://ss3krkmqcf.execute-api.us-east-1.amazonaws.com/default/soul/";
   const URL2 ="https://static-content-for-text.s3.amazonaws.com/deep+sleep+music+5+min%2Cdeep+sleep+relaxing+music+for+sleeping%2C5+minute+relaxation+music+for+sleep.mp3";
    const [isClicked, setIsClicked] = useState("row fadecss");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [audioRes, setAudioRes] = useState([
    {
        "title": "",
        "story": "",
        "result": {
            "message": "",
            "audioUrl": ""
        }
    }
]);
    const [getrecordRes, setGetrecordRes] = useState([]);
    const handleSubmit = (e) => {
        setIsClicked("row element");
        setTimeout(() => {
            navigate('/offer');
        }, 500);
    }
    useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'video');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    useEffect(() => {
        setLoading(true);
        createAudioRecordings()        
    }, [])
  // Function to handle button click
  const handleClick = () => {
    setPlayAudio(true);
    // After 10 seconds, change the screen
    
    setTimeout(() => {
      setIsClicked("row element");
      setPlayAudio(false);
      navigate('/offer');
    }, 15000);
  };

  const createAudioRecordings = async (e) => {
        const response = await fetch(URL + 'create-audio-recordings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userID": localStorage.getItem("id") ,
                "limit": "1" 
           })
        });
        const json = await response.json();
        setAudioRes(json);
        console.log(json);
    }
    useEffect(() => {
         setTimeout(() => {
           getRecording();
            
        }, 15000);
        
    }, [])
  const getRecording = async (e) => {
        const response = await fetch(URL + 'getUserRecordings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userID": localStorage.getItem("id"),
                 
           })
        });
        const json = await response.json();
        setGetrecordRes(json);
        setLoading(false);
        console.log(json);
    }
	return (
    <div className={isClicked} style={{ paddingTop: '50px' }}>
    {loading ? (
        <LoadingSpinner />
      ) :(
        <div className="col-sm-12 col-lg-12 text-center">
            {playAudio && (
                <audio autoPlay>
                  <source src={audioRes[0].result.audioUrl} />
                  Your browser does not support the audio element.
                </audio>
              )}
              {playAudio && (
                <audio autoPlay>
                  <source src={URL2} />
                  Your browser does not support the audio element.
                </audio>
              )}


            <div className="yourval_vidoe mb-3 btn video_img_s" onClick={handleClick}>
            <span className="play_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
            </svg>
            </span>

            <span className="yourval_text_v">SOUL <button  className="btn_vidos btn mb-3" value="continue" onClick={handleClick}>ORIGINAL FOR YOU</button> </span> <br/>
            <span className="yourval_text2_v">{audioRes[0].title}</span>
            <br/>
            {playAudio ?
             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-pause-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5m3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5"/>
            </svg>:<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
            </svg>
            }
            </div> 
            <br/>
            <br/>


            {getrecordRes.map((message, index) => (
              <div key={index} className="yourval_vidoe_2 mb-3 btn" onClick={handleSubmit} style={{ 
            backgroundImage: `url(${message.thumbnail})`}}>
            <span className="lock_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
            </svg>
            </span>
            <span className="yourval_text_v">SOUL<br/><button className="btn_vidos_l btn mb-3" value="continue" onClick={handleSubmit}>ORIGINAL FOR YOU</button></span> <br/>
            <span className="yourval_text2_v_1 col-lg-4 col-sm-4">{message.title}</span> 
            </div>
            ))}

            <br/>
            <br/>
            <br/>
            <br/>
            <div>
              {/*<h1>Audio Player</h1>*/}
              {/*<AutoPlayAudio audioSrc="img/Meditation.mp3" />
              <span className="video_vl_text">Your Value</span>*/}
            </div>

           <button className="skipbtn btn mb-3" value="continue" onClick={handleSubmit}>View More Originals</button> <br/>
            <NavLink to="#" onClick={handleSubmit}>Many more Soul Originals<br/> about you await!</NavLink>
            <br/>
            <br/>
        </div>)
        };
    </div>
    );
}