import React, { useEffect,useState  } from 'react'
import {NavLink,useNavigate} from 'react-router-dom'
export default function Callback(props) {
 useEffect(() => {
        // Trigger the Purchase event when component mounts
        // eslint-disable-next-line
        fbq('track', 'callback-web');
         return () => {
          // Optional cleanup code here
          console.log('Component unmounted');
        };
      }, []);
    return (
    <div className="row fadecss">
        <div className="col-sm-12 col-lg-12 text-center">
             <br/>
              <br/>
               <br/>
            <h2>Welcome, {localStorage.getItem("name")}!</h2>
             <p>Email: {localStorage.getItem("email")}</p>
            <div>
            </div> 
             <br/> <br/> <br/>
            <br/>
            <NavLink to="https://apps.apple.com/app/soul-personal-sleep-stories/id6479317391" >Explore The App <br/> <br/><img src="img/appstore.svg" width="150" height="37px"/></NavLink>
             <br/>
              <br/>
        </div>
    </div>
    );
}