import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import stripePromise from './stripe';


const PaymentPage = () => {
      const productPrice = 5.99; // Set your product price here
      const successUrl = '/success'; // Set your success URL here
      const failureUrl = '/failure'; // Set your failure URL here
  return (
    <div className="row fadecss">
        <div className="col-sm-12 col-lg-12 text-center">
            <h1 className="stayh1"> Pay $5.99</h1>
            <div className="checkout" >
              <Elements stripe={stripePromise}>
                <CheckoutForm price={productPrice} successUrl={successUrl} failureUrl={failureUrl} />
              </Elements>
            </div>
            <br/>
            <br/>
            <br/>
             <br/>
            <br/> <br/>
            <br/>
            <br/>
        </div>
    </div>
  );
};

export default PaymentPage;
